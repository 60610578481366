<template>
  <VRow
    justify="center"
    class="mt-16"
  >
    <VCol
      cols="12"
      class="text-center"
    >
      <h1 class="font-weight-medium ma-2">
        {{ $t('surveyNotActiveStub.oops') }}
      </h1>

      <div class="mb-6">
        {{ $t('surveyNotActiveStub.noSuchPage') }}
      </div>

      <VBtn
        depressed
        color="tt-blue white--text"
        class="text-none"
        data-test="goto-btn"
        :ripple="false"
        :href="link"
      >
        {{ $t('surveyNotActiveStub.toSurveysPage') }}
      </VBtn>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: 'SurveyNotActiveStub',

  computed: {
    link() {
      return `${process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS}app/surveys`;
    },
  },
};
</script>
